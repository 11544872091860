import React, { useEffect, useContext } from 'react'
import { Box, Heading, Text } from 'theme-ui'
import Img from "gatsby-image"
import Partners from '../components/partners'
import Packaging from '../components/packaging'
import Logistics from '../components/logistics'
import LinkBox from '../components/linkbox'
import { graphql, useStaticQuery } from 'gatsby'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import LangContext from "../components/Context/LangContext"
import { handleLinkLanguage } from "../components/helpers"

const Wholesalepage = ({ data }) => {
    const {
        setPage, currentPage, setLang, currentLang
      } = useContext(LangContext)

    useEffect(() => {
        setPage("wholesale")
    }, [])

    useEffect(() => {
        gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);
        gsap.from('.logo', { opacity: 0, ease: "expo", })

        let test1 = []
        let test2 = []

        gsap.utils.toArray(".splittext").forEach((text, index) => {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: text,
                    start: 'top bottom',
                    end: 'bottom top',
                    toggleActions: "play none none none"
                }
            });
            test1.unshift(new SplitText(text, {
                type: "words,lines",
                position: 'absolute',
                linesClass: `lineChild${index}`
            }));
            test2.unshift(new SplitText(text, {
                type: "words,lines",
                position: 'absolute',
                linesClass: "o-hidden"
            }));

            tl.from(`.lineChild${index}`, { opacity: 0, y: 50, ease: "expo", stagger: 0.05, });
        })

        ScrollTrigger.create({
            trigger: '#partnerListBox',
            start: 'top 90%',
            end: 'bottom top',
            animation: gsap.from('.partnerstype-list', { opacity: 0, stagger: 0.2, ease: "expo", }),
            scrub: false,
            // toggleActions: "play none none reset",
            // markers: true
        })

        if (typeof window === 'undefined') return;

        const handleResize = () => {
            console.log('111', test1)
            // tl.progress(1); 
            test1.forEach(x => { console.log(x); x.revert() });
            test2.forEach(x => x.revert());
        }
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);

        }
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []
    )


    const {
        titleEn,
        image,
        descriptionEn,
        descriptionEnNode,
        partnersTitleEn,
        partnersDescriptionEn,
        partnersTypes = [],
        packagingTitleEn,
        packagingDescriptionEn,
        packingOptions = [],
        logisticsTitleEn, 
        logisticsDescriptionEn, 
        logisticsImage,
        linksComponent = [],
    } = data.allDatoCmsWholesale.nodes[0]

    const partnersTypesRemap = partnersTypes.map(x => {
        return {
            title: x.titleEn
        }
    })

    const packagingOptionsRemaped = packingOptions.map(x => {
        return {
            title: x.titleEn,
            description: x.descriptionEnNode.childMarkdownRemark.html,
            image: x.image,

        }
    })

    const linksComponentRemap = linksComponent.map(x => {
        return {
            title: x.titleEn,
            image: x.image,
            link: handleLinkLanguage(x.link, currentLang),
        }
    })

    const {
        gap,
    } = data.allDatoCmsGlobalImage.nodes[0]

    return (
        <Box>
            <Box
                sx={{
                    width: "100%",
                    display: 'flex',
                    flexDirection: ['column', 'column', 'row'],
                    position: 'relative',
                    height: ['100vh', '100vh', '100vh'],
                    justifyContent: ['center', 'center', 'flex-end'],
                    textAlign: ['center', 'center', 'inherit'],
                    mb: 5,

                }}>


                {/* Hero Slika */}
                <Box
                    sx={{
                        opacity: 0.5,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',


                        '& .gatsby-image-wrapper': {
                            height: '100%',
                            width: '100%',
                        }
                    }}>
                    <Img fluid={image.fluid} />
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: ['50%', '50%', '100%'],
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: ['center', 'center', 'flex-end'],
                        alignItems: ['center', 'center', 'inherit'],
                        textAlign: ['center', 'center', 'inherit'],
                        zIndex: 1,
                        pb: [0, 0, 5],
                        pl: [0, 0, 5],

                    }}>
                    <Heading className='splittext'
                        sx={{
                            fontSize: ['15vw', 8, '130px'],
                            color: 'white',

                        }}>
                        {titleEn}
                    </Heading>

                    <Text className='splittext'
                        sx={{
                            pl: [0, 0, 4],
                            pb: [0, 0, 3],
                            fontSize: [5, 6, 4],
                            color: 'white',
                        }}>
                        {descriptionEn}
                    </Text>
                </Box>
                <Box className='logo'
                    sx={{
                        position: ['absolute', 'absolute', 'absolute'],
                        right: ['50%', '50%', '50px'],
                        transform: ['translateX(50%)', 'none'],
                        bottom: [5, 5, 75],
                        width: 140,
                        height: 140,
                        borderRadius: '50%',
                        bg: 'white',
                        pt: '20px',
                        flexShrink: 0,
                        mr: ['0%', '0%', '0%'],
                        mt: ['0%', '0%', '33%'],

                    }}>
                    <Img fluid={gap.fluid} imgStyle={{ width: '100%', height: '100%', objectFit: 'contain', }} />
                </Box>


            </Box>
            <Partners 
            partnersTitle={partnersTitleEn}
            partnersDescription={partnersDescriptionEn}
            partnersTypes={partnersTypesRemap}
            ></Partners>
            <Packaging 
            packagingTitle={packagingTitleEn}
            packagingDescription={packagingDescriptionEn}
            packingOptions={packagingOptionsRemaped}
            currentLang={currentLang}
            ></Packaging>
            <Logistics
            logisticsTitle={logisticsTitleEn}
            logisticsDescription={logisticsDescriptionEn}
            logisticsImage={logisticsImage}
            ></Logistics>
            <LinkBox linksComponent={linksComponentRemap}></LinkBox>
        </Box>
    )
}
export default Wholesalepage
export const query = graphql`
query Wholesale {
    allDatoCmsWholesale {
      nodes {
        image {
          fluid {
              ...GatsbyDatoCmsFluid
          }
        }
        descriptionEn
        titleEn
        partnersDescriptionEn
        partnersTitleEn
        partnersTypes {
            titleEn
        }
        packagingTitleEn
        packagingDescriptionEn

        packingOptions {
            image {
                fluid {
                    ...GatsbyDatoCmsFluid
                }
            }
            titleEn
            descriptionEn
            descriptionEnNode {
                childMarkdownRemark {
                  html
                }
              }
        }
        logisticsTitleEn
        logisticsDescriptionEn
        logisticsImage {
            fluid {
                ...GatsbyDatoCmsFluid
            }
        }
        linksComponent {
            titleEn
            link
            image {
                fluid{
                    ...GatsbyDatoCmsFluid
                }
            }
        }
      }
    }
    allDatoCmsGlobalImage {
        nodes {
        gap {
        fluid {
            ...GatsbyDatoCmsFluid
        }
      }
    }
  }
  }`